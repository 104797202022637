import {
  GLOBAL_ROLES_WITH_ACCOUNT_PERMISSION,
  GLOBAL_ROLES_WITH_WRITE_PERMISSION,
} from './data/constant';

import { NavigationGuardWithContext } from '$common/bootstrappers/router/middleware-processor';
import { useAuthStore } from '$common/modules/auth/stores/auth.ts';
import { useRbacStore } from '$common/modules/auth/stores/rbac.ts';

export const Auth: NavigationGuardWithContext = (
  _from,
  to,
  next,
  // ctx: unknown
) => {
  const authStore = useAuthStore();

  if (!authStore.isLoggedIn) {
    next({
      name: 'Login',
      query: { from: to.path, ...to.query },
    });
  } else {
    next();
  }
};

export const Guest: NavigationGuardWithContext = (
  from,
  to,
  next,
  // ctx: unknown
) => {
  const authStore = useAuthStore();

  if (authStore.isLoggedIn) {
    let route: { path: string; query?: Record<string, string> } = {
      path: '/',
      query: { from: to.path, ...to.query },
    };

    if (from.name === 'Login') {
      route = { path: '/' };
    }

    next(route);
  } else {
    next();
  }
};

export const HasAnyRoles =
  (roles: string[], strict = false): NavigationGuardWithContext =>
  (
    _from,
    _to,
    next,
    // ctx: unknown
  ) => {
    const rbacStore = useRbacStore();

    if (rbacStore.hasAnyRoles(roles, strict)) {
      next();
    } else {
      next('/');
    }
  };

export const HasAllRoles =
  (roles: string[], strict = false): NavigationGuardWithContext =>
  (
    _from,
    _to,
    next,
    // ctx: unknown
  ) => {
    const rbacStore = useRbacStore();

    if (rbacStore.hasAllRoles(roles, strict)) {
      next();
    } else {
      next('/');
    }
  };

/**
 * Whether the user has read/write permissions.
 * TODO can be extended by accepting two arguments defining the type of entity and its id.
 */
export const HasWritePermission = HasAnyRoles(GLOBAL_ROLES_WITH_WRITE_PERMISSION);

/**
 * Whether the user has account read/write permissions.
 */
export const HasAccountPermissions = HasAnyRoles(GLOBAL_ROLES_WITH_ACCOUNT_PERMISSION);

export default {
  Auth,
  Guest,
  HasAnyRoles,
  HasAllRoles,
  HasWritePermission,
  HasAccountPermissions,
};
