import { NavigationGuardWithContext } from '$common/bootstrappers/router/middleware-processor.ts';
import { useAuthStore } from '$common/modules/auth/stores/auth.ts';

export const IsMyself: NavigationGuardWithContext = (_from, to, next) => {
  const { currentUser } = useAuthStore();

  if (!currentUser) {
    next({
      name: 'Login',
      query: { from: to.path, ...to.query },
    });
    return;
  }

  const profileId = Number(to.params.profile);

  if (!profileId) {
    next('/');
    return;
  }

  if (profileId !== currentUser.id) {
    next({
      name: 'ProfileGeneralView',
      params: { profile: profileId },
    });
    return;
  }

  next();
};
