import { defineStore } from 'pinia';
import { computed, ref } from 'vue';

import * as auth from '$common/modules/auth/data/auth.api';
import { useRbacStore } from '$common/modules/auth/stores/rbac.ts';
import { UserInWindow } from '$tenant/models/users.ts';

export const useAuthStore = defineStore('Auth', () => {
  const rbacStore = useRbacStore();

  const user = ref<UserInWindow | null>(window.user ?? null);
  const isLoggedIn = computed(() => user.value !== null);
  const currentUser = computed(() => user.value);

  /**
   * Clears the user from state and redirects to login page.
   */
  function clear() {
    user.value = null;
    rbacStore.clear();
    window.location.pathname = '/login';
  }

  /**
   * Clears the state and send the user to redirect route.
   */
  async function logout() {
    await auth.logout();

    clear();
  }

  return {
    isLoggedIn,
    currentUser,
    logout,
    clear,
    setAvatar(url: string) {
      if (user.value) {
        user.value.avatar_url = url;
      }
    },
    setName(name: string) {
      if (user.value) {
        user.value.name = name;
      }
    },
    setEmail(email: string) {
      if (user.value) {
        user.value.email = email;
      }
    },
  };
});
