import { RouteRecordRaw } from 'vue-router';

import { IsMyself } from './middlewares/is-myself.ts';

export const routes: RouteRecordRaw[] = [
  {
    path: ':profile?',
    component: () => import('./layouts/ProfileLayout.vue'),
    children: [
      {
        path: '',
        name: 'ProfileGeneralView',
        component: () => import('./views/ProfileGeneralView.vue'),
        meta: { title: 'Profile' },
      },
      {
        path: 'security',
        name: 'ProfileSecurityView',
        component: () => import('./views/ProfileSecurityView.vue'),
        meta: {
          title: 'Profile Security',
          middlewares: [IsMyself],
        },
      },
    ],
  },
];
