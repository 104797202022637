import * as Sentry from '@sentry/vue';
import type { AxiosError, AxiosRequestConfig, AxiosResponse, CreateAxiosDefaults } from 'axios';
import axios from 'axios';
import axiosRetry from 'axios-retry';
import { stringify } from 'qs';
import { Notify } from 'quasar';

import { useAuthStore } from '$common/modules/auth/stores/auth.ts';

const config: CreateAxiosDefaults = {
  baseURL: location.origin,
  withCredentials: true,
  paramsSerializer: (params) => stringify(params),
  headers: {
    common: {
      ['X-Requested-With']: 'XMLHttpRequest',
    },
  },
};

export interface QueryParams {
  include?: string[];
  fields?: string[];
  sort?: string[];
  select?: string[];
  filter?: (`${string}` | `-${string}`)[];
}

const separator = ',';

function paramFormatter(params: QueryParams) {
  return Object.keys(params).reduce(
    (acc, paramsName) => ({
      ...acc,
      // @ts-expect-error quasar QueryParams is not properly typed as array-like
      [paramsName]: params[paramsName].join(separator),
    }),
    {},
  );
}

const client = axios.create(config);

axiosRetry(client, {
  retries: 3,
  retryDelay: axiosRetry.exponentialDelay,
  onRetry: (retryCount: number, error: AxiosError, requestConfig: AxiosRequestConfig) => {
    Sentry.addBreadcrumb({
      category: 'axios',
      message: `Retry attempt #${retryCount} for ${requestConfig.url}`,
      level: 'warning',
      data: { error, requestConfig },
    });

    return;
  },
});

client.interceptors.response.use(
  (response) => response,
  async function (error) {
    if (error?.response) {
      await handleUnauthorizedError(error);
      handleBadRequestError(error);
      handleServerError(error);
    }

    if (axios.isAxiosError(error)) {
      const axiosError = error;

      // ignore cancelation errors
      if (axios.isCancel(error)) {
        return {};
      }

      return Promise.reject({
        type: 'AxiosError',
        error: axiosError,
        response: axiosError.response,
        request: { ...axiosError.request },
        requestConfig: axiosError.request.config,
      });
    }

    return Promise.reject(error);
  },
);

async function handleUnauthorizedError({ response }: { response?: AxiosResponse }) {
  if (response?.status !== 401) {
    return;
  }

  // failed login attempt
  if (location.pathname === '/login') {
    return;
  }

  const store = useAuthStore();

  // already logged out
  if (response?.config?.url === '/auth/logout') {
    store.clear();
    return;
  }

  await store.logout();
}

function handleBadRequestError({ response }: { response?: AxiosResponse }) {
  if (!response?.status) {
    return;
  }

  if (response?.status < 400) {
    return;
  }

  if (response?.status >= 500) {
    return;
  }

  const message =
    response?.data?.message ??
    'Your request could not be processed. Please adjust your input and try again.';

  Notify.create({
    message,
    icon: 'fas fa-exclamation-triangle',
    type: 'negative',
  });
}

function handleServerError({ response }: { response?: AxiosResponse }) {
  if (!response?.status) {
    return;
  }

  if (response?.status < 500) {
    return;
  }

  Notify.create({
    icon: 'fas fa-bomb',
    message: 'An error occurred. Please try again later.',
    type: 'negative',
  });
}

export { client, paramFormatter };
