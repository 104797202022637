import { useAxios } from '@vueuse/integrations/useAxios';
import { MaybeRef, toValue } from 'vue';

import { client } from '$common/services/http';

/**
 * Refresh the sanctum cookie.
 */
export async function csrf() {
  return client.get('/sanctum/csrf-cookie');
}

/**
 * Attempt to login with given credentials.
 */
export async function login(email: string, password: string) {
  // extra cookie refresh to be sure the session is up and running
  await csrf();

  const { data } = await client.post<{ two_factor: boolean }>('/auth/login', { email, password });

  return data;
}

/**
 * Logout current user.
 */
export async function logout() {
  const { data } = await client.post('/auth/logout');

  return data;
}

export interface PasswordChangePayload {
  current_password: string;
  password: string;
  password_confirmation: string;
}

export function useChangePassword() {
  const { data, execute, isLoading } = useAxios<unknown>('', client, {
    immediate: false,
  });

  return {
    isLoading,
    data,
    execute: (payload: MaybeRef<PasswordChangePayload>) =>
      execute('/auth/user/password', {
        method: 'PUT',
        data: toValue(payload),
      }),
  };
}

export async function forgottenPassword(email: MaybeRef<string>) {
  return (await client.post('/auth/forgot-password', { email: toValue(email) }))?.data ?? {};
}

export interface RequestResetPasswordPayload {
  email: string;
  token: string;
  password: string;
  password_confirmation: string;
}

export async function requestResetPassword(payload: MaybeRef<RequestResetPasswordPayload>) {
  return (await client.post('/auth/reset-password', toValue(payload)))?.data ?? {};
}

export function useConfirmPassword() {
  const { data, execute, isLoading } = useAxios<unknown>('', client, {
    immediate: false,
  });

  return {
    isLoading,
    data,
    execute: (password: MaybeRef<string>) =>
      execute('/auth/user/confirm-password', {
        method: 'POST',
        data: { password: toValue(password) },
      }),
  };
}
